import './MainLogo.css';

function MainLogo(): JSX.Element {
  return (
    <div className="logoContainer">
      <img src={'/logo54.png'} alt="Logo" className="logo" />
      <h3>Video Search AI</h3>
    </div>
  );
}

export default MainLogo;
