import React, { useEffect, useState } from 'react';
import './chatHistorySearch.css';
import { CiSearch } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';

// Define the type for chat history results
interface ChatHistory {
  chatHistoryId: number;
  chatText: string;
  ftsScore: number;
  trigramScore: number;
  combinedScore: number;
}

interface TrimmedHistory {
  chatHistoryId: number;
  text: string;
}

const ChatHistorySearch: React.FC = () => {
  const token = localStorage.getItem('token'); // Retrieve the token
  const nav = useNavigate();
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState<ChatHistory[]>([]);

  const getInitialHistory = async () => {
    const url = '/chat/history';
    try {
      const jsonData = {
        maxHistorySize: 20,
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify(jsonData),
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }
        throw new Error(`Network response was not ok`);
        // TODO should set a variable here
      }
      const json = await response.json();
      const history: TrimmedHistory[] = JSON.parse(json);
      const validHistory = history.map(
        (item) =>
          ({
            chatHistoryId: item.chatHistoryId,
            chatText: item.text,
            ftsScore: 0,
            trigramScore: 0,
            combinedScore: 0,
          }) as ChatHistory
      );
      setSearchResults(validHistory);
    } catch (error) {
      // TODO should set a variable here
    }
  };

  const handleSearchSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch('/chat/history/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: query,
          sortBy: 'combined',
          threshold: null,
          maxCount: 20,
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }
        throw new Error('Network response was not ok');
      }
      const jsonData = JSON.parse(await response.json());
      const data: ChatHistory[] = jsonData as ChatHistory[];
      setSearchResults(data);
    } catch (error) {
      console.error('Error fetching chat history', error);
    }
  };

  useEffect(() => {
    getInitialHistory();
  }, []);

  return (
    <div className="historySearch-container">
      <form onSubmit={handleSearchSubmit}>
        <div>
          <CiSearch className="search-icon" />
          <input
            placeholder="Search your chats..."
            type="text"
            className="search-input"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </form>

      <div className="chat-list">
        {searchResults.map((chat) => (
          <div className="chat-message" key={chat.chatHistoryId}>
            <p>{chat.chatText}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatHistorySearch;
