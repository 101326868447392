export const validateEmail = (email: string): boolean => {
  if (!email) return false; // Ensure the email isn't empty

  const atIndex = email.indexOf('@');
  const dotIndex = email.lastIndexOf('.');

  // Check if "@" exists, is not the first/last character, and "." comes after "@"
  return (
    atIndex > 0 && // "@" is not the first character
    dotIndex > atIndex + 1 && // "." comes after "@" with at least one character between
    dotIndex < email.length - 1 // "." is not the last character
  );
};


export const validatePassword = (password: string): boolean => {
  return password.length >= 8 && password.length <= 32;
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url); // This will throw if the URL is invalid
    return true;
  } catch (e) {
    return false;
  }
}