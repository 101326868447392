import { useNavigate } from 'react-router-dom';
import './SignOut.page.css';

const Signout = () => {
  const navigate = useNavigate();

  const handleSignout = () => {
    localStorage.removeItem('token'); // Remove token from localStorage
    navigate('/signin'); // Redirect to signin page
  };

  return (
    <button onClick={handleSignout} className="SignOutButton">
      Sign Out
    </button>
  );
};

export default Signout;
