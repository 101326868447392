import React, { ChangeEvent, useState } from 'react';
import './DropDownMenu.css';

interface Props {
  choices: string[];
  onSelect: (value: string) => void;
}

function DropDownMenu({ choices, onSelect }: Props): JSX.Element {
  const [selectedValue, setSelectedValue] = useState<string>(choices[0]);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value);
    onSelect(event.target.value);
  };

  return (
    <div className="DropDown-container">
      <select
        value={selectedValue}
        onChange={handleChange}
        className="DropDown-select"
      >
        {choices.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropDownMenu;
