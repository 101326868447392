import React, { useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import './AdminSideBarContent.css';

interface Props {
  nav: NavigateFunction;
}

function AdminSideBarContent({ nav }: Props): JSX.Element {
  return (
    <div className="AdminSideBarContentContainer">
      <ul className="AdminSideBarContentList">
        <li
          onClick={() => {
            nav('/admin');
          }}
        >
          <span>Dashboard</span>
        </li>
        <li
          onClick={() => {
            nav('/admin/transcripts');
          }}
        >
          <span>Transcripts</span>
        </li>
        <li
          onClick={() => {
            nav('/admin/upload');
          }}
        >
          <span>Upload</span>
        </li>
      </ul>
    </div>
  );
}

export default AdminSideBarContent;
