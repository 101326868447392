import React from 'react';
import './GenericTopBar.css';
import MainLogo from '../../../components/MainLogo';

interface Props {
  sticky: boolean;
  topBarChildren: React.ReactNode;
}

/**
 * This is a glorified row container intended to be put in the top of pages.
 * It will verticaly center content and horizontally space them evenly.
 * @returns
 */
function GenericTopBar({ sticky, topBarChildren }: Props): JSX.Element {
  return (
    <div
      className={`GenericTopBarContainer ${sticky && 'GenericTopBarContainerSticky'}`}
    >
      {topBarChildren}
    </div>
  );
}

export default GenericTopBar;
