import './TabForm.css';
import React, { useState } from 'react';

interface TabContent {
  tabLabel: string;
  tabChildren: React.ReactNode;
}

interface Props {
  tabContent: TabContent[];
  onTabSelect: (index: number) => void;
}

function TabForm({ tabContent, onTabSelect }: Props): JSX.Element {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  return (
    <div className="TabFormContainer">
      <div className="TabFormTabContainer">
        {tabContent.map((tab, index) => (
          <button
            key={index}
            className={`TabFormTab ${activeTabIndex === index ? 'TabFormActiveTab' : ''}`}
            onClick={() => {
              onTabSelect(index);
              setActiveTabIndex(index);
            }}
          >
            {tab.tabLabel}
          </button>
        ))}
      </div>

      {/* Render Active Tab Content */}
      <div className="TabFormContent">
        {tabContent[activeTabIndex]?.tabChildren}
      </div>
    </div>
  );
}

export default TabForm;
