import React, { ChangeEvent, useEffect, useState } from 'react';
import './SidePanelScaffold.css';
import '../../Shared.css';
import GenericSideBar from '../../panels/SideBars/GenericSideBar';
import { FaBars } from 'react-icons/fa';
import GenericTopBar from '../../panels/TopBars/GenericTopBar';

interface Props {
  stickyTopBar: boolean;
  isExpanded: boolean;
  showCollapseIcons: boolean;
  onExpandedToggle: () => void;
  topBarChildren?: React.ReactNode;
  sideBarChildren?: React.ReactNode;
  mainPageChildren?: React.ReactNode;
}

function SidePanelScaffoldPage({
  stickyTopBar,
  isExpanded,
  showCollapseIcons,
  onExpandedToggle,
  topBarChildren,
  sideBarChildren,
  mainPageChildren,
}: Props): JSX.Element {
  return (
    <div className="SidePanelScaffoldContainer">
      <GenericSideBar transition={true} rounded={true} isExpanded={isExpanded}>
        {showCollapseIcons && (
          <FaBars
            onClick={onExpandedToggle}
            className="SidePanelScaffoldToggleButton"
          />
        )}
        {sideBarChildren}
      </GenericSideBar>
      <div className="SidePanelScaffoldPageContainer">
        <GenericTopBar
          sticky={stickyTopBar}
          topBarChildren={
            <>
              {!isExpanded && showCollapseIcons && (
                <FaBars
                  onClick={onExpandedToggle}
                  className="SidePanelScaffoldToggleButton"
                />
              )}
              {topBarChildren}
            </>
          }
        />
        {mainPageChildren}
      </div>
    </div>
  );
}

export default SidePanelScaffoldPage;
