import React from 'react';
import './PageBlocker.css';

interface Props {
  isDismissable: boolean;
  onDismiss: () => void;
  children: React.ReactNode;
}

function PageBlocker({
  isDismissable,
  onDismiss,
  children,
}: Props): JSX.Element {
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDismissable && e.target === e.currentTarget) {
      onDismiss();
    }
  };
  return (
    <div className="PageBlockerContainer" onClick={handleOverlayClick}>
      {children}
    </div>
  );
}

export default PageBlocker;
