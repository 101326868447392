import React from 'react';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const nav = useNavigate();
  const signInClicked = () => {
    nav('/signin');
  };

  const chatClicked = () => {
    nav('/chat');
  };

  const adminClicked = () => {
    nav('/admin');
  };

  return (
    <div>
      <button onClick={signInClicked}>Sign In</button>
      <button
        onClick={() => {
          nav('/signup');
        }}
      >
        Sign Up
      </button>
      <button onClick={chatClicked}>Chat</button>
      <button onClick={adminClicked}>Admin</button>
    </div>
  );
}

export default HomePage;
