import React, { ChangeEvent, useEffect, useState } from 'react';
import './Chat.page.css';
import '../../ui/Shared.css';
import { IoMdAdd, IoMdSend } from 'react-icons/io';
import { IoLogoElectron } from 'react-icons/io5';
import { IoSettingsOutline } from 'react-icons/io5';
import InputField from '../../ui/components/InputField';
import DropDownMenu from '../../ui/components/DropDownMenu';
import NumberInputField from '../../ui/components/NumberInputField';
import SourceCard from '../../ui/components/SourceCard';
import LargeInputField from '../../ui/components/LargeInputField';
import { useNavigate } from 'react-router-dom';
import MainLogo from '../../ui/components/MainLogo';
import SidePanelScaffold from '../../ui/pageScaffolds/SidePanelScaffold';
import { FaRegMessage } from 'react-icons/fa6';
import { FaSync } from 'react-icons/fa';
import LoadingSpinner from '../../ui/components/LoadingSpinner';
import HoveringCard from '../../ui/components/HoveringCard';

const isMock = process.env.REACT_APP_MOCK === 'true';

interface Source {
  videoTitle: string;
  videoURL: string;
  relevancy: string;
  associatedStudies: {
    studyTitle: string;
    studyURL: string;
    studyAbstract: string;
    studyKeywords: string;
  }[];
}

interface TrimmedHistory {
  chatHistoryId: number;
  text: string;
}

function ChatPage() {
  const token = localStorage.getItem('token'); // Retrieve the token
  const nav = useNavigate();
  const [query, setQuery] = useState<string>('');
  const [hide, setHide] = useState(true);
  const [educationLevel, setEducationLevel] = useState<string>(
    'A high school student'
  );
  const levels = ['Short', 'Medium', 'Long'];
  const [detailLevel, setDetailLevel] = useState<string>(levels[0]);
  const [threshold, setThreshold] = useState<number>(40);
  const [botResponse, setBotResponse] = useState<string>('');
  const [sources, setSources] = useState<Source[]>([]);
  const [sourceError, setSourceError] = useState('');
  const [chatId, setChatId] = useState<number>(-1); // -1 to indicate no chatId is assigned yet, perhapse null would be better?
  const [ws, setWs] = useState<WebSocket | null>(null);

  const [sideBarExpanded, setSideBarExpanded] = useState<boolean>(true);

  const [recentHistory, setRecentHistory] = useState<TrimmedHistory[]>([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [historyError, setHistoryError] = useState(false);
  // TODO need to make a more robust method of selecting entities
  const [selectableEntities, setSelectableEntities] = useState<string[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleEducationLevelChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEducationLevel(event.target.value);
  };

  const handleThresholdChange = (value: number) => {
    setThreshold(value);
  };

  const toggleSideBarExpansion = () => {
    setSideBarExpanded(!sideBarExpanded);
  };

  const getInitialEntities = async () => {
    const url = '/validentities';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }
        throw new Error(`Network response was not ok`);
        // TODO should set a variable here
      }
      const json = await response.json();
      const entities: string[] = json;
      setSelectableEntities(entities);
      setSelectedEntities(entities);
    } catch (error) {
      // TODO should set a variable here
    }
  };

  const startWebSocket = (callback?: () => void) => {
    // Close the previous WebSocket if it's already open
    const mockEndpoint = isMock ? '/mock' : '';

    if (ws) {
      ws.close();
      setWs(null);
    }

    // Initialize the WebSocket connection
    let socket: WebSocket;
    if (process.env.REACT_APP_ENV === 'local') {
      socket = new WebSocket(`ws://localhost:3000/chatbot${mockEndpoint}`);
    } else {
      socket = new WebSocket(
        `${window.location.origin.replace(/^http/, 'ws')}/chatbot${mockEndpoint}`
      );
    }

    // Set the WebSocket on open connection and call the callback if provided
    socket.onopen = () => {
      if (token) {
        socket.send(JSON.stringify({ type: 'auth', token })); // Send the token as the first message
      }
      setWs(socket);
      if (callback) {
        callback(); // Send query only when WebSocket is open
      }
    };

    // Handle incoming messages
    socket.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case 'chatId':
          setChatId(data.id!); // Assume chatId is number
          break;
        case 'aiResponseChunk':
          setBotResponse((prev) => prev + data.text!);
          break;
        case 'messageEnd':
          // use this if you need a flag for the message is finished
          break;
        case 'noSources':
          setBotResponse(data.text!);
          break;
        case 'aiSources':
          if (data.sources) {
            try {
              const newSources = data.sources as Source[];
              // setSourceError('');
              setSources(newSources);
            } catch (error) {
              setSourceError(`There was an error when getting sources`);
            }
          }
          break;
        case 'inputError':
          console.log(`an error occured with inputs`);
          // TODO prompt user that an error occured and to adjust inputs?
          break;
        case 'serverError':
          console.log(`an error occured with the server`);
          // TODO Tell user server had an issue and maybe try again later
          break;
        case 'authError':
          nav('/signin');
          break;
        default:
          console.error('Unknown message type:', data.type);
          console.log(data);
      }
    };

    socket.onerror = (error: Event) => {
      console.error('WebSocket error:', error);
    };

    // Cleanup WebSocket connection on component unmount
    return () => {
      socket.close();
    };
  };

  const sendQuery = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const payload = {
        query: query,
        educationLevel: educationLevel,
        detailLevel: detailLevel,
        threshold: threshold,
        selectedEntities: selectedEntities,
        chatId: chatId === -1 ? null : chatId,
      };

      ws.send(JSON.stringify(payload));
      setBotResponse('');
      setQuery('');
    }
  };

  const handleSubmit = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      sendQuery();
    } else {
      startWebSocket(sendQuery); // Ensure WebSocket is open before sending the query
    }
  };

  const getRecentHistory = async () => {
    const url = '/chat/history';
    setLoadingHistory(true);
    try {
      const jsonData = {
        maxHistorySize: 10,
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify(jsonData),
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }

        throw new Error(`Network response was not ok`);
      }
      const json = await response.json();
      const history = JSON.parse(json) as TrimmedHistory[];
      setLoadingHistory(false);
      setRecentHistory(history);
    } catch (error) {
      setLoadingHistory(true);
      setHistoryError(true);
    }
  };

  // Get required background info
  useEffect(() => {
    getInitialEntities();
    getRecentHistory();
  }, []);

  // Set up the WebSocket on component mount
  useEffect(() => {
    startWebSocket();
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const handleHide = () => {
    setHide(!hide);
  };

  return (
    <SidePanelScaffold
      stickyTopBar={true}
      isExpanded={sideBarExpanded}
      showCollapseIcons={true}
      onExpandedToggle={toggleSideBarExpansion}
      sideBarChildren={
        <div>
          <div className="ChatSideBarNewChatButton">
            <IoMdAdd /> <span>New Chat</span>
          </div>
          <div className="ChatSideBarHistoryHeaderRow">
            <label className="RecentHistoryHeaderText">Recent Chats</label>
            {!loadingHistory && (
              <FaSync
                className="ChatSideBarHistoryRefreshButton"
                onClick={getRecentHistory}
              />
            )}
          </div>

          <div className="ChatListContainer">
            {loadingHistory ? (
              <LoadingSpinner />
            ) : (
              <ul className="chat-list">
                {recentHistory.map((chat) => (
                  <li key={chat.chatHistoryId}>
                    <FaRegMessage /> <span>{chat.text}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="show-more">
            <span
              onClick={() => {
                nav('/chat/history/search');
              }}
            >
              Show More
            </span>
          </div>
        </div>
      }
      mainPageChildren={
        <div className="primaryChatContainer">
          <div className="ChatPageMainWrapper">
            {sources.length > 0 && sourceError === '' ? (
              <div className="ChatPageSourceColumn">
                {sources.map((source, index) => (
                  <SourceCard source={source} key={index} />
                ))}
              </div>
            ) : (
              <>
                {sourceError != '' && (
                  <div className="ChatPageSourceColumn">
                    <HoveringCard>
                      <label>{sourceError}</label>
                    </HoveringCard>
                  </div>
                )}
              </>
            )}
            <div
              className={
                botResponse !== '' ? 'ChatPageChatArea' : 'defaultWidth'
              }
            >
              <div className="ChatPageResponse">
                {botResponse !== '' ? (
                  <div className="responseAreaWrapper">
                    <IoLogoElectron className="defaultScreenIcon" />
                    <div className="ChatPageBotResponse">{botResponse}</div>
                  </div>
                ) : (
                  <div className="defaultScreen">
                    <img src={'/logo54.png'} alt="Logo" className="logo" />
                    <h4 className="defaultScreenName">Video Search AI</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="ChatSearchContainer">
            <div className="ChatPageQuery">
              <LargeInputField
                value={query}
                label={'Query'}
                onValueChange={handleQueryChange}
                onEnterPress={handleSubmit}
              ></LargeInputField>
              <IoMdSend
                onClick={handleSubmit}
                className="ChatPageConfirmButton"
              />
            </div>
          </div>
        </div>
      }
      topBarChildren={
        <>
          <MainLogo />
          <div className="gearIconDiv">
            <IoSettingsOutline
              className="gearIcon"
              onClick={() => {
                handleHide();
              }}
            />
            <div className={`ChatPageSetting ${hide ? 'hidden' : ''}`}>
              <div>
                <p>Education Level:</p>
                <InputField
                  value={educationLevel}
                  label={'Education Level'}
                  onValueChange={handleEducationLevelChange}
                ></InputField>
              </div>
              <div>
                <p> Detail Amount: </p>
                <DropDownMenu
                  choices={levels}
                  onSelect={(index) => setDetailLevel(index)}
                ></DropDownMenu>
              </div>
              <div>
                <p>Relevancy Threshold:</p>
                <NumberInputField
                  value={threshold}
                  label={'Threshold'}
                  onValueChange={handleThresholdChange}
                ></NumberInputField>
              </div>
            </div>
          </div>
        </>
      }
    ></SidePanelScaffold>
  );
}

export default ChatPage;
