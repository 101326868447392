import { NavigateFunction } from 'react-router-dom';
import './BugReportForm.css';
import React, { ChangeEvent, useState } from 'react';
import LargeFormPanel from '../LargeFormPanel';
import InputField from '../../../components/InputField';
import ExpandingTextArea from '../../../components/ExpandingTextArea';
import LoadingSpinner from '../../../components/LoadingSpinner';

interface Props {
  parentPageUrl: string;
  token: string | null;
  nav: NavigateFunction;
  handleCloseFormPressed: () => void;
}

function BugReportForm({
  parentPageUrl,
  token,
  nav,
  handleCloseFormPressed,
}: Props): JSX.Element {
  const [pageUrl, setPageUrl] = useState<string>(parentPageUrl);
  const [bugDescription, setBugDescription] = useState<string>('');
  const [loadingSendBugReport, setLoadingSendBugReport] =
    useState<boolean>(false);
  const [sendBugReportError, setSendBugReportError] = useState<boolean>(false);

  const handlePageUrlChange = (value: ChangeEvent<HTMLInputElement>) => {
    if (sendBugReportError) {
      setSendBugReportError(false);
    }
    setPageUrl(value.target.value);
  };

  const handleBugReportChange = (value: ChangeEvent<HTMLTextAreaElement>) => {
    if (sendBugReportError) {
      setSendBugReportError(false);
    }
    setBugDescription(value.target.value);
  };

  const handleSubmitPressed = async () => {
    const url = `/bug`;
    try {
      const jsonData = {
        pageUrl: pageUrl,
        bugDescription: bugDescription,
      };
      setLoadingSendBugReport(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(jsonData),
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }
        throw new Error(`Network response was not ok ` + response.statusText);
      }
      setLoadingSendBugReport(false);
      handleCloseFormPressed();
    } catch {
      setLoadingSendBugReport(false);
      setSendBugReportError(true);
    }
  };

  return (
    <LargeFormPanel
      title={'Bug Report Form'}
      handleCloseFormPressed={
        !loadingSendBugReport ? handleCloseFormPressed : () => {}
      }
      formChildren={
        <div>
          {!loadingSendBugReport && (
            <div className="BugReportFormContainer">
              <div className="BugReportFormRow">
                <span className="BugReportFormLabel">Page:</span>
                <InputField
                  value={pageUrl}
                  label={''}
                  onValueChange={handlePageUrlChange}
                ></InputField>
              </div>
              <div className="BugReportFormSpacer" />
              <span className="BugReportFormLabel">Bug Description:</span>
              <div className="BugReportFormRow">
                <ExpandingTextArea
                  maxLines={10}
                  value={bugDescription}
                  label={''}
                  onValueChange={handleBugReportChange}
                />
              </div>
              <div className="BugReportFormSpacer" />
              {sendBugReportError && (
                <div>
                  <p>There was an error when trying to send your bug report</p>
                  <div className="BugReportFormSpacer" />
                </div>
              )}
              <div className="BugReportFormRow">
                <div>
                  <button onClick={handleSubmitPressed}>Submit</button>
                </div>
              </div>
            </div>
          )}
          {loadingSendBugReport && (
            <div className="BugReportFormCenteredRow">
              <LoadingSpinner />
            </div>
          )}
        </div>
      }
    ></LargeFormPanel>
  );
}

export default BugReportForm;
