import { FaBug } from 'react-icons/fa';
import './BugButton.css';
import React, { useState } from 'react';
import BugReportForm from '../../panels/Forms/BugReportForm';
import { NavigateFunction } from 'react-router-dom';

interface Props {
  parentPageUrl: string;
  token: string | null;
  nav: NavigateFunction;
}

function BugButton({ parentPageUrl, token, nav }: Props): JSX.Element {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const handleFormToggle = () => {
    setIsFormOpen(!isFormOpen);
  };

  return (
    <div>
      <button className="BugButton" onClick={handleFormToggle}>
        <FaBug />
      </button>
      {isFormOpen && (
        <BugReportForm
          parentPageUrl={parentPageUrl}
          token={token}
          nav={nav}
          handleCloseFormPressed={handleFormToggle}
        />
      )}
    </div>
  );
}

export default BugButton;
