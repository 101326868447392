import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignUp.page.css';
import img1 from '../../../images/capsule-grp.png';
import img2 from '../../../images/illustration-grp.png';
import MainLogo from '../../../ui/components/MainLogo';
import {
  validateEmail,
  validatePassword,
} from '../../../helpers/ValidationUtils';

// TODO refactor right side of the page, also maybe refactor some of these div classes, very excessive
// TODO Redo organization of this page, why is it all centered to middle rather than sectioned

const Signup: React.FC = () => {
  const nav = useNavigate();

  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    password: '',
  });

  const [loading, setLoading] = useState(false);

  const [userNameError, setUserNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [submissionError, setSubmissionError] = useState('');

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (submissionError != '') {
      setSubmissionError('');
    }
    if (!validateEmail(e.target.value) && e.target.value !== '') {
      setEmailError(`Invalid Email Format`);
    } else {
      setEmailError(``);
    }
    setFormData({ ...formData, email: e.target.value });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (submissionError != '') {
      setSubmissionError('');
    }
    if (!validatePassword(e.target.value) && e.target.value !== '') {
      setPasswordError(`Invalid password length`);
    } else {
      setPasswordError(``);
    }
    setFormData({ ...formData, password: e.target.value });
  };

  const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (submissionError != '') {
      setSubmissionError('');
    }
    if (e.target.value.length < 5 && e.target.value !== '') {
      setUserNameError(`Invalid User Name length`);
    } else {
      setUserNameError(``);
    }
    setFormData({ ...formData, userName: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    if (emailError !== '' || formData.email == '') return;
    if (passwordError !== '' || formData.password == '') return;
    setLoading(true);
    try {
      const res = await fetch('/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!res.ok) {
        if (res.status === 400) {
          throw new Error(`An account may already be made with this email`);
        }
        throw new Error('Sign up failed. Please try again.');
      }
      setSubmissionError('');
      const data = await res.json();
      localStorage.setItem('token', data.token); // Store token after signup
      nav('/'); // Redirect to protected route
    } catch (error: any) {
      if (error.message) {
        setSubmissionError(error.message);
      }
    }
    setLoading(false);
  };

  return (
    <div className="SignUpPageContainer">
      <div className="SignUpLeftContainer">
        <MainLogo />
        <h1 className="SignUpHeading">
          Sources and Responses at Your Fingertips
        </h1>
        <div className="SignUpBox">
          <h2 className="SignUpTitle">Sign Up</h2>
          <input
            type="text"
            name="userName"
            placeholder="Username"
            value={formData.userName}
            onChange={handleUserNameChange}
            className="SignUpInput"
          />
          {userNameError !== '' && formData.userName !== '' && (
            <label>{userNameError}</label>
          )}
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleEmailChange}
            className="SignUpInput"
          />
          {emailError != '' && formData.email != '' && (
            <label>{emailError}</label>
          )}
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handlePasswordChange}
            className="SignUpInput"
          />
          {passwordError != '' && formData.password != '' && (
            <label>{passwordError}</label>
          )}
          <button className="SignUpButton" onClick={handleSubmit}>
            Sign Up
          </button>
          {submissionError != '' && <label>{submissionError}</label>}
        </div>
      </div>

      {/* Right Side */}
      <div className="SignUpRightContainer">
        <div className="SignUpRightContent">
          <div className="SignUpImageContainer">
            <p className="SignUpCaption">
              “ Video Search AI, Find me a video related to Vitamin-D and it's
              Effects
            </p>
            <img src={img1} alt="Capsule" className="SignUpFloatingImage" />
          </div>
          <div className="SignUpImageContainer2">
            <img
              src={img2}
              alt="Illustration"
              className="SignUpFloatingImage2"
            />
            <p className="SignUpCaption2">
              “ Also find me all the related references for the video
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
