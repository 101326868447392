import React, { ChangeEvent, useState } from 'react';
import './FeedBackForm.css';
import LargeFormPanel from '../LargeFormPanel';
import InputField from '../../../components/InputField';
import LargeInputField from '../../../components/LargeInputField';
import ExpandingTextArea from '../../../components/ExpandingTextArea';
import { NavigateFunction } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner';

interface Props {
  parentPageUrl: string;
  token: string | null;
  nav: NavigateFunction;
  handleCloseFormPressed: () => void;
}

function FeedBackForm({
  parentPageUrl,
  token,
  nav,
  handleCloseFormPressed,
}: Props): JSX.Element {
  const [pageUrl, setPageUrl] = useState<string>(parentPageUrl);
  const [feedback, setFeedBack] = useState<string>('');
  const [loadingSendFeedBack, setLoadingSendFeedBack] =
    useState<boolean>(false);
  const [sendFeedBackError, setSendFeedBackError] = useState<boolean>(false);

  const handlePageUrlChange = (value: ChangeEvent<HTMLInputElement>) => {
    if (sendFeedBackError) {
      setSendFeedBackError(false);
    }
    setPageUrl(value.target.value);
  };

  const handleFeedBackChange = (value: ChangeEvent<HTMLTextAreaElement>) => {
    if (sendFeedBackError) {
      setSendFeedBackError(false);
    }
    if (value.target.value.length < 256) {
      setFeedBack(value.target.value);
    } else {
      console.log(`set an error and notify user`);
    }
  };

  const handleSubmitPressed = async () => {
    const url = `/feedback`;
    try {
      const jsonData = {
        pageUrl: pageUrl,
        feedBack: feedback,
      };
      setLoadingSendFeedBack(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(jsonData),
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }
        throw new Error(`Network response was not ok ` + response.statusText);
      }
      setLoadingSendFeedBack(false);
      handleCloseFormPressed();
    } catch {
      setLoadingSendFeedBack(false);
      setSendFeedBackError(true);
    }
  };

  return (
    <LargeFormPanel
      title={'Feed Back Form'}
      handleCloseFormPressed={
        !loadingSendFeedBack ? handleCloseFormPressed : () => {}
      }
      formChildren={
        <div>
          {!loadingSendFeedBack && (
            <div className="FeedBackFormContainer">
              <div className="FeedBackFormRow">
                <span className="FeedBackFormLabel">Page:</span>
                <InputField
                  value={pageUrl}
                  label={''}
                  onValueChange={handlePageUrlChange}
                ></InputField>
              </div>
              <div className="FeedBackFormSpacer" />
              <span className="FeedBackFormLabel">Feed Back:</span>
              <div className="FeedBackFormRow">
                <ExpandingTextArea
                  maxLines={10}
                  value={feedback}
                  label={''}
                  onValueChange={handleFeedBackChange}
                />
              </div>
              <div className="FeedBackFormSpacer" />
              {sendFeedBackError && (
                <div>
                  <p>There was an error when trying to send your feedback</p>
                  <div className="FeedBackFormSpacer" />
                </div>
              )}
              <div className="FeedBackFormRow">
                <div>
                  <button onClick={handleSubmitPressed}>Submit</button>
                </div>
              </div>
            </div>
          )}
          {loadingSendFeedBack && (
            <div className="FeedBackFormCenteredRow">
              <LoadingSpinner />
            </div>
          )}
        </div>
      }
    ></LargeFormPanel>
  );
}

export default FeedBackForm;
