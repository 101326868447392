import React, { useState } from 'react';
import './GenericSideBar.css';

interface Props {
  transition: boolean;
  rounded: boolean;
  isExpanded: boolean;
  children?: React.ReactNode;
}

function GenericSideBar({
  transition,
  rounded,
  isExpanded,
  children,
}: Props): JSX.Element {
  return (
    <div
      className={`GenericSideBar ${transition && 'transition'} ${rounded && 'rounded'} ${!isExpanded && 'collapsed'}`}
    >
      {children}
    </div>
  );
}

export default GenericSideBar;
