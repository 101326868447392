import './ExpandingTextArea.css';
import React, { useEffect, useRef } from 'react';

interface Props {
  maxLines: number;
  value: string;
  label: string;
  onValueChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

function ExpandingTextArea({
  maxLines,
  value,
  label,
  onValueChange,
}: Props): JSX.Element {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = textareaRef.current;

    if (textarea) {
      // Reset the height to allow shrinking
      textarea.style.height = 'auto';

      // Dynamically calculate the height based on content
      const lineHeight = parseInt(
        getComputedStyle(textarea).lineHeight || '24',
        10
      );
      textarea.style.height = `${Math.min(
        textarea.scrollHeight,
        lineHeight * maxLines
      )}px`;
    }

    // Call the parent handler
    onValueChange(e);
  };

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea) {
      // Reset the height to allow shrinking
      textarea.style.height = 'auto';

      // Dynamically calculate the height based on content
      const lineHeight = parseInt(
        getComputedStyle(textarea).lineHeight || '24',
        10
      );
      textarea.style.height = `${Math.min(
        textarea.scrollHeight,
        lineHeight * maxLines
      )}px`;
    }
  }, [textareaRef]);

  return (
    <div className="expanding-textarea-container">
      <label className="expanding-textarea-label">{label}</label>
      <textarea
        ref={textareaRef}
        value={value}
        onChange={handleInput}
        className="expanding-textarea"
        placeholder="Type here..."
        rows={3} // Start with 1 row
      />
    </div>
  );
}

export default ExpandingTextArea;
