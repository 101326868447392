import React from 'react';
import './SourceCard.css';
import HoveringCard from '../HoveringCard';

interface Study {
  studyTitle: string;
  studyURL: string;
  studyAbstract: string;
  studyKeywords: string;
}

interface Source {
  videoTitle: string;
  videoURL: string;
  relevancy: string;
  associatedStudies: Study[];
}

export interface Props {
  source: Source;
}

function SourceCard({ source }: Props): JSX.Element {
  return (
    <HoveringCard>
      <div>
        <a
          href={source.videoURL}
          target="_blank"
          rel="noopener noreferrer"
          className="SourceCardLinkText"
        >
          {source.videoTitle}
        </a>
        <div className="SourceCardRelevancyContainer">
          <h4 className="SourceCardRelevancyScore">
            {source.relevancy}% Relevancy
          </h4>
          <div className="SourceCardRelevanceBarContainer">
            <div
              className="SourceCardRelevanceBar"
              style={{ width: `${source.relevancy}%` }}
            />
          </div>
        </div>
      </div>
    </HoveringCard>
  );
}

export default SourceCard;
