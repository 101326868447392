import './LargeFormPanel.css';
import React, { useState } from 'react';

interface Props {
  title: string;
  handleCloseFormPressed: () => void;
  formChildren?: React.ReactNode;
}

function LargeFormPanel({
  title,
  handleCloseFormPressed,
  formChildren,
}: Props): JSX.Element {
  return (
    <div>
      {
        <div className="LargeFormPanelOverlay" onClick={handleCloseFormPressed}>
          <div className="LargeFormPanel" onClick={(e) => e.stopPropagation()}>
            <div className="LargeFormPanelHeader">
              <h2 className="LargeFormPanelTitle">{title}</h2>
              <div>
                <button
                  className="LargeFormPanelCloseButton"
                  onClick={handleCloseFormPressed}
                  aria-label="Close"
                >
                  &times;
                </button>
              </div>
            </div>
            <div className="LargeFormPanelContent">{formChildren}</div>
          </div>
        </div>
      }
    </div>
  );
}

export default LargeFormPanel;
