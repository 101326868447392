import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminPage from './adminPages/Admin/Admin.page';
import ChatPage from './pages/Chat/Chat.page';
import Signin from './pages/User/SignIn/SignIn.page';
import Signup from './pages/User/SignUp/SignUp.page';
import PrivateRoute from './helpers/protectedRoutes';
import UploadPage from './adminPages/Upload/Upload.page';
import HomePage from './pages/Home/Home.page';
import ChatHistorySearch from './ui/components/chatHistorySearch/chatHistorySearch';
import Test from './pages/Test/Test.page';
import TranscriptsPage from './adminPages/Transcripts/Transcripts.page';

//TODO move ChatHistorySearch from being a route to being part of the side bar expansion

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/chat" element={<ChatPage />} />
      <Route
        path="/admin"
        element={
          <PrivateRoute>
            {' '}
            <AdminPage />{' '}
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/upload"
        element={
          <PrivateRoute>
            {' '}
            <UploadPage />{' '}
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/transcripts"
        element={
          <PrivateRoute>
            {' '}
            <TranscriptsPage />{' '}
          </PrivateRoute>
        }
      />
      <Route path="/signin" element={<Signin />} />
      <Route path="/signup" element={<Signup />} />

      <Route path="/chat/history/search" element={<ChatHistorySearch />} />
      {/* <Route path="/test" element={<Test />} /> */}
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
